import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";

const OptimizelyWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);

const AnalyticsWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);

const ToastProvider = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.ToastProvider,
  })),
);
const UserConfigWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigWrapper,
  })),
);
const ThemeProvider = lazy(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const ExpandableSearchProvider = lazy(() =>
  import("@/contexts/expandableSearch/ExpandableSearchContext").then(
    (module) => ({ default: module.ExpandableSearchProvider }),
  ),
);
const IntlProvider = lazy(() =>
  import("@/i18n").then((module) => ({ default: module.IntlProvider })),
);
const HomePage = lazy(() => import("@/pages/homePage/HomePage"));

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<div></div>}>
        <ThemeProvider>
          <UserConfigWrapper>
            <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
              <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                <IntlProvider>
                  <ToastProvider>
                    <ExpandableSearchProvider>
                      <BrowserRouter>
                        <HomePage />
                      </BrowserRouter>
                    </ExpandableSearchProvider>
                  </ToastProvider>
                </IntlProvider>
              </AnalyticsWrapper>
            </OptimizelyWrapper>
          </UserConfigWrapper>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}
